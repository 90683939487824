@import "../../styles/variables-mixins";

.section-wrapper {
    margin: 1rem 0;

    .container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        transition: transform .2s ease-in-out;
        
        .background-section {
            width: 95vw;
            height: auto;
            box-shadow: $boxShadow;
            transition: box-shadow .2s ease-in-out;
        }

        div {
            position: relative;
            color: rgb(255, 255, 255);
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 1.2rem;
            position: absolute;
            height: 5rem;
            width: 95vw;
            transition: background-color .2s ease-in-out;

            .background-title {
                position: absolute;
                margin-top: 1rem;
                height: 4rem;
                width: 100%;
                opacity: 1;
            }
            
            .title{
                padding-top: 0.8rem;
                font-size: 1.3rem;
                z-index: 1;
            }
        }
    }
}

@media only screen and (min-width: 600px) {
    .section-wrapper {
        margin: 1rem;
    }

    .background-section {
        max-width: 20rem;
    }
    
    .container div {
        max-width: 20rem;
    }   
}

@media only screen and (min-width: 1024px) {
        
    .container{
        margin: 2rem 1rem 1.5rem;

        &:hover {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }

        .background-section  {
            max-width: 27rem;
        
            &:hover{
                box-shadow: none;
            } 
        }

        div {
            max-width: 27rem;
        }   
    }
}


