@import "../../styles/variables-mixins";

.footer-container {
    @include flexCenter();
    flex-direction: column;
    font-size: 1.1rem;
    font-weight: 500;
    height: 11rem;
    width: 100%;
    background-color: $yellow;
    border-top: 1px solid black;
}

.icons-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
}

.social-icons {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
}

.chiSiamo{
    justify-content: center;
    margin-top: 1rem;
}

.logoAbout {
    height: 2rem;
    width: auto;
}

.madeBy {
    margin: 1.6rem 0 0 0;
    padding: 0;
    font-size: 0.8rem;
}

@media only screen and (min-width: 1000px) {
    .icons-wrapper {
        width: 60%;
    }
}