.whatsapp {
  cursor: pointer;
  animation: pulse 2s infinite ease-out;
}

@keyframes pulse {
  0% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.3);
  }
}
  