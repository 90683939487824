@import "../../styles/variables-mixins";

.header-wrapper {
    @include flexCenter();
}

.catch-line-container {
    @include flexCenter();
    margin-bottom: 1.5rem;

    p {
        margin: 0 2rem;
        font-weight: 500;
        text-align: center;
    }
}

@media only screen and (min-width: 500px) {

    .catch-line-container p{
        margin: 0 2rem;
        max-width: 50rem;
    }
}