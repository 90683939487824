@import "../../styles/variables-mixins";

nav {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $yellow;
    padding: 0.8rem 0.5rem;
    height: 3.5rem;
    position: sticky;
    top: 0;
    border-bottom: 1px solid black;
    margin-bottom: 0.5rem;
    z-index: 10;

    .wrapper {
        width: 100%;

        .nav-links {

            .nav-icon {
                height: auto;
                width: 30px; 
                padding: 0 0.1rem;
            }
        
            .shoe {
                height: 40px;
                width: auto;
            }
                
            .giftbox {
                height: 27px;
                width: auto;
                padding-left: 0.3rem;
            }
            .home {
                width: 40px;
            }
        }
    }

    #close .nav-links{
        display: none;
    }
    
    #close .logo-nav{
        display: flex;
        height: 50px; 
    }
    
    #open .nav-links{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        animation: slide-in .8s ease-in-out;
    }

    #open .logo-nav {
        display: none;
    }

    .logo-nav {
        padding-left: 3rem;
    }

    .nav-btn {
        display: flex;
        border: none;
        background-color: $yellow;
        padding-right: 1.2rem;
        cursor: pointer;   
    }
}

@keyframes slide-in {
    0% {
      transform: translateX(-400px)
    }
    100% {
      transform: translateX(0px);
    }
}

@media only screen and (min-width: 600px) {

    nav {

        .wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .nav-links {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 25rem;
                padding-right: 2.5rem;
        
                .nav-icon {
                    height: auto;
                    width: 35px; 
                }
        
                .home {
                    width: 45px;
                }
            
                .shoe {
                    height: 45px;
                    width: auto;
                }
                
                .giftbox{
                    height: 32px;
                    width: auto;
                }
            }
        }

        #open .nav-links{
            display: flex;
            animation: none;
        }
    
        #close .nav-links {
            display: flex;
            animation: none;
        }
    
        #open .logo-nav {
            display: flex;
            height: 50px;
        }
        
        .nav-btn {
            display: none;
        }
    }
}