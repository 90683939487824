@import "../../styles/variables-mixins";

.image-gid-container {
    @include flexCenter();
    background-color: $yellow;
    border-bottom: 1px solid black;
    position: sticky;
    top: 5.1rem;
    height: 2rem;
}

.img-grid{
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2rem 0.2rem;
    gap: 0.2rem 0.2rem;

    img {
        width: 100%;
        height: 150px;
        object-fit: cover;
        box-shadow: $boxShadow;
    }
}

.img-list{
    display: flex;
    flex-direction: column;
    margin: 1rem 0.5rem;
    
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: .5rem 0;
        box-shadow: $boxShadow;
    }
}

@media only screen and (min-width: 800px) {
    .image-gid-container {
        display: none;
    }

    .img-grid{
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        justify-content: center;
        align-items: center;
        gap: 2rem;
        margin: 3rem 2rem 3rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            box-shadow: $boxShadow;
            transition: transform .2s ease;
            cursor: pointer;
            &:hover{
                -webkit-transform: scale(1.02);
                transform: scale(1.02);
                box-shadow: none;
            }
        }
    }
}