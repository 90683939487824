@import "../../styles/variables-mixins";

.backdrop{
    @include flexCenter();
    z-index: 100;
    position: fixed;
    top: 0rem;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);

    img{
        display: block;
        max-width: 95%;
        max-height: auto;
        box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
    }
}


@media only screen and (min-width: 600px) {
    .backdrop img {
        max-width: 95%;
        max-height: 95%;
    }
}