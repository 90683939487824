@import "../src/styles/variables-mixins";

body {
  margin: 0;
  background-color: rgb(241, 241, 241);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  &::-webkit-scrollbar {
    width: .6rem;
  }
  
  &::-webkit-scrollbar-track {
    background: $yellow;
  }
  
  &::-webkit-scrollbar-thumb {
    background: rgb(58,58,58);
  }
  
  &::-webkit-scrollbar-thumb:hover {
    background: rgb(58,58,58);
  }

  a:link,
  a:visited,
  a:hover,
  a:active {
    text-decoration: none;
    color: rgb(0, 0, 0);
  }
}

